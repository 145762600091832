import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { shared_service } from 'app/shared/shared.service';

@Component({
  selector: 'ashcorp-field-textarea',
  templateUrl: './ashcorp-field-textarea.component.html',
  animations: fuseAnimations
})

export class Ashcorp_Field_TextArea_Component {
  @Input() form: FormGroup;
  @Input() label: string;
  @Input() name: string;
  @Input() show?: boolean = true;
  @Input() rows?: number = 1;

  public helper: shared_service;

  constructor(private _shared_service: shared_service) {
    this.helper = this._shared_service;
  }
}

import { Component, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { shared_service } from 'app/shared/shared.service';
import { FormGroup } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'ashcorp-field-date',
  templateUrl: './ashcorp-field-date.component.html',
  animations: fuseAnimations
})

export class Ashcorp_Field_Date_Component {
  @Input() form: FormGroup;
  @Input() label: string;
  @Input() name: string;
  @Input() show?: boolean = true;

  public helper: shared_service;

  constructor(private _shared_service: shared_service) {
    this.helper = this._shared_service;
  }

  changeDatePicker() {
    let value = moment(this.form.get(this.name).value ?? new Date()).format('YYYY-MM-DD');
    this.form.get(this.name).setValue(value);
  }
}

export class crumb {
    name: string;
    url: string;
}

export class breadcrumb {
    title: string;
    crumbs: Array<crumb>;
    search: boolean = false;
    company: boolean = false;
    branch: boolean = true;
}

export interface breadcrumb_button {
    name: string;
    url: string;
    icon: string;
}
<div *ngIf="table" class="items-center justify-between w-full">
    <div class="xl:col-span-1 flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden">
        <div class="grid grid-cols-4 gap-4 p-6">
            <div class="col-span-2">
                <div class="text-lg font-medium tracking-tight leading-6 truncate" style="line-height: 40px !important;display: flex;">
                    <ng-container *ngIf="table.back">
                        <button matripple class="mx-1"
                                matTooltip="Go back to previous page"
                                (click)="event({name: 'back'},table.back,null,null);"
                                class="mat-stroked-button mr-3">
                            <mat-icon class="icon-size-6" svgIcon="heroicons_solid:chevron-left"></mat-icon>
                        </button>
                    </ng-container>
                    <mat-icon *ngIf="table.icon" class="icon-size-6 mr-3" [svgIcon]="table.icon"></mat-icon>
                    <div *ngIf="table.title" class="mr-3">{{table.title}}</div>
                    <ng-container *ngFor="let filter of table.filters">
                        <button matripple class="mx-1" *ngIf="_.access(filter.access)"
                                [matTooltip]="filter.label"
                                (click)="event({name: 'filter'},filter.params,null,null);"
                                [ngClass]="(filter.class ?? '') + ' mat-stroked-button'">
                            <mat-icon class="icon-size-6" [svgIcon]="filter.icon"></mat-icon>
                        </button>
                    </ng-container>
                </div>
            </div>
            <div class="col-span-2 text-right" *ngIf="table.actions">
                <div class="text-lg font-medium tracking-tight leading-6 truncate">
                    <ng-container *ngFor="let action of table.actions">
                        <button matripple class="mx-1" *ngIf="_.access(action.access)"
                                [matTooltip]="action.label"
                                (click)="event(action.click,action.params,null,null);"
                                [ngClass]="action.class ?? 'mat-stroked-button'">
                            <mat-icon class="icon-size-6" [svgIcon]="action.icon"></mat-icon>
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto mx-6">

            <table class="w-full bg-transparent" mat-table matSort [dataSource]="table.data_display" [trackBy]="table.tracker" #viewTable multiTemplateDataRows>

                <ng-container *ngFor="let header of table.header" [matColumnDef]="header">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        {{header.replace("_"," ")}}
                    </th>
                    <td mat-cell *matCellDef="let row; let i = dataIndex;">
                        <div [@toggle]="i === table.idx ? 'close' : 'open'" [ngClass]="number(header, row[header]) ? 'text-right' : ''">
                            <span class="pr-6 font-medium text-sm whitespace-nowrap" [ngClass]="row.class ?? ''">
                                {{ number(header, row[header]) ? (row[header] | number:'1.2-2') : row[header] }}
                            </span>
                        </div>
                    </td>
                </ng-container>

                <ng-container *ngIf="table.action" matColumnDef="Actions">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row;let i = dataIndex">
                        <div [@toggle]="i === table.idx ? 'close' : 'open'" class="m-1">
                            <ng-container *ngFor="let action of table.action">
                                <button matripple class="mx-1" *ngIf="_.access(action.access) && i !== table.more_idx"
                                        [matTooltip]="action.label"
                                        (click)="event(action.click,action.params,i,row);"
                                        [ngClass]="action.class ?? 'mat-stroked-button'">
                                    <mat-icon class="icon-size-6" [svgIcon]="action.icon"></mat-icon>
                                </button>
                            </ng-container>
                        </div>
                    </td>
                </ng-container>

                <!-- <ng-container matColumnDef="more">
                    <td mat-cell *matCellDef="let row;let i = dataIndex;" [attr.colspan]="table.full_header.length">
                        <div [@toggle]="i === table.idx ? 'open' : 'close'">
                            <ng-container *ngTemplateOutlet="more"></ng-container>
                        </div>
                    </td>
                </ng-container> -->

                <tr mat-header-row *matHeaderRowDef="table.full_header"></tr>
                <tr mat-row *matRowDef="let row; columns: table.full_header;" style="height:0px;"></tr>
                <!-- <tr mat-row *matRowDef="let row; columns: ['more'];" style="height:0px;"></tr> -->
            </table>

            <mat-paginator [pageSizeOptions]="table.sizes" showFirstLastButtons></mat-paginator>

        </div>
    </div>
</div>
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { navigation_service } from 'app/core/navigation/navigation.service';
import { company } from 'app/core/system/company.types';
import { environment } from 'environments/environment';
import { FormControl } from '@angular/forms';
import { shared_service } from 'app/shared/shared.service';

@Component({
    selector: 'template-layout',
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.css', './template.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default
})
export class TemplateLayoutComponent implements OnInit, OnDestroy {
    public cdn: string = environment.cdn_url;
    public search_key: string = "";
    public search_text: string = "";

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    public showMobileMenu = false;
    public showMobileSubMenu = "";
    public current_url = window.location.href;
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: navigation_service,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fusenavigation_service: FuseNavigationService,
        private _sharedService: shared_service
    ) {
        this._activatedRoute.params.subscribe(params => {
            _sharedService.sKey("").subscribe(
                (response: any) => {
                    this.search_key = response;
                    this.search_text = localStorage.getItem('search_text') ?? "";
                })
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.search_text = "";
        this.search_key = "";



        // // Subscribe to navigation data
        // this._navigationService.navigation$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((navigation: Navigation) => {
        //         this.navigation = navigation;
        //     });

        // // Subscribe to media changes
        // this._fuseMediaWatcherService.onMediaChange$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(({ matchingAliases }) => {

        //         // Check if the screen is small
        //         this.isScreenSmall = !matchingAliases.includes('md');
        //     });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    search() {
        localStorage.setItem('search_text', this.search_text);
        this.search_key = this._sharedService.slugify(this.search_text, "-");
        this._sharedService.sKey(this.search_key);
        this._router.navigateByUrl("/search/" + this.search_key);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleMobileMenu(): void {
        this.showMobileMenu = !this.showMobileMenu;
    }

    toggleMobileSubMenu(submenu: string): boolean {
        this.showMobileSubMenu == submenu ? this.showMobileSubMenu = "" : this.showMobileSubMenu = submenu;
        return !this.showMobileMenu;
    }
}

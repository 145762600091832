import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { company } from '../system/company.types';
import { Router } from '@angular/router';
import { UserService } from '../user/user.service';
import { branch } from '../system/branch.types';
import { api_response } from 'app/shared/shared.types';
import { environment } from 'environments/environment';
import { breadcrumb } from './breadcrumb.types';

@Injectable({
    providedIn: 'root'
})
export class navigation_service {
    private _search: ReplaySubject<any> = new ReplaySubject<any>(1);
    private _company: ReplaySubject<company> = new ReplaySubject<company>(1);
    private _branch: ReplaySubject<company> = new ReplaySubject<company>(1);

    private _breadcrumb: ReplaySubject<breadcrumb> = new ReplaySubject<breadcrumb>(1);

    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    public company: company;
    public branch: branch;

    public is_debug: boolean;
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _router: Router,
        private _user_service: UserService
    ) {
        this.company$.subscribe((company: company) => { this.company = company });
        this.branch$.subscribe((branch: branch) => { this.branch = branch });

        let b = new breadcrumb();
        b.title = "Dashboard";
        b.crumbs = [];
        this._breadcrumb.next(b);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }
    get breadcrumb$(): Observable<breadcrumb> {
        return this._breadcrumb.asObservable();
    }
    get company$(): Observable<company> {
        return this._company.asObservable();
    }
    get branch$(): Observable<company> {
        return this._branch.asObservable();
    }
    get search$(): Observable<string> {
        return this._search.asObservable();
    }

    build_path(...paths: any[]) {
        return paths.join("/").replace("//", "/");
    }
    // set company(value: any) {
    //     this._company.next(value);
    // }
    // set branch(value: any) {
    //     this._branch.next(value);
    // }
    set breadcrumb(value: breadcrumb) {
        this._breadcrumb.next(value);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<api_response<any>> {
        return this._httpClient
            .post('api/dashboard/navigation', {
                access_token: localStorage.getItem('access_token')
            })
            .pipe(
                tap((response: api_response<any>) => {
                    //TODO:: CATCHING ERROR
                    // if (response.code == 401) {
                    //     alert("LOGIN");
                    // }
                    this.is_debug = response.payload.debug;
                    this._company.next(response.payload.selected[0]);
                    this._branch.next(response.payload.selected[1]);
                    this._navigation.next(response.payload);
                })
            );
    }

    /**
     * Get all navigation data
     */
    switch_company(item: any) {
        return this._httpClient
            .post('api/dashboard/navigation', {
                access_token: localStorage.getItem('access_token'),
                type: "company",
                selection: item.id
            }).subscribe((response: api_response<any>) => {
                //TODO:: CATCHING ERROR
                // if (response.code == 401) {
                //     alert("LOGIN");
                // }
                this._company.next(response.payload.selected[0]);
                this._branch.next(response.payload.selected[1]);
                this._navigation.next(response.payload);
            });
    }

    switch_branch(item: any) {
        return this._httpClient
            .post('api/dashboard/navigation', {
                access_token: localStorage.getItem('access_token'),
                type: "branch",
                selection: item.id
            }).subscribe((response: api_response<any>) => {
                //TODO:: CATCHING ERROR
                // if (response.code == 401) {
                //     alert("LOGIN");
                // }
                this._company.next(response.payload.selected[0]);
                this._branch.next(response.payload.selected[1]);
                this._navigation.next(response.payload);
            });
    }

    search(text: any) {
        this._search.next(text);
    }

    goto(url: string) {
        this._router.navigate([url]);
    }

    download(url: string) {
        window.open(environment.cdn_url + url, "_blank");
    }

    access(level: number, log: string = null) {
        //TODO:: CORRECT ACCESS
        return true;
        let access = this._user_service.current.role <= level;
        if (log) {
            console.log(log + " Level:" + level + " Role:" + this._user_service.current.role + " Access:" + access);
        }
        return access;
    }

    get debug(): boolean {
        return this.is_debug;
    }
}
